.container{
    /* flex-grow: 1; */
    display: flex;
    margin-bottom: 30px;
    min-height: 300px;
    gap: 30px;
    border-radius: 10px;
    border: 4px solid #ddd;
    /* background-color: #ffb006; */
    padding: 20px;
    box-sizing: border-box;
}
.heading{
    height: 50px;
    font-size: 30px;
    align-items: center;
    gap: 20px;
    display: flex;
}
.container img{
    object-fit: cover;
    width: 195px;
}
.description{
    margin-top: 20px;
    font-size: 20px;
}
.name{
    font-size: 50px;
}
.tag{
    border-radius: 10px;
    font-size: 25px;
    align-items: center;
    padding: 3px 10px;
    width: fit-content;
}

.tag.CSE{
    background-color: #004e66;
    color: white;
}
.tag.BME{
    background-color: #04d29b;
}