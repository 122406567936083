.container{
    border-radius: 10px;
    border: 3px solid #ddd;
    font-size: 30px;
    overflow: hidden;
}
.angleData{
    display: flex;
}
.angleData > *{
    flex-grow: 1;
    padding: 20px;
    border-right: 3px solid #ddd;
    font-weight: 300;
}
.angleData > *:last-child{
    border: none;
}
.topHeading{
    color: gray;
    font-size: 25px;
    text-align: left;
}
.number{
    font-size: 90px;
}
.verdict{
    text-align: center;
    padding: 15px;
}
.verdict.safe{
    background-color: #7CD147;
}
.verdict.unsafe{
    background-color: #FAA775;

}